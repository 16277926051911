import React, { Component } from "react";

class NavBar extends Component {
  state = {
    active: "active",
  };

  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-darkkk fixed-top "
        style={{ boxShadow: "0px 2px 5px #303030", backgroundColor: "#101010" }}
      >
        <div className="d-flex flex-grow-1">
          {/* <span className="w-100 d-lg-none d-block">Center Logo</span> */}
          <a className="navbar-brand" href="#home">
            <img src="/img/LogoWhite.svg" width="120px" alt="" />
          </a>
          <div className="w-100 text-right">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#myNavbar7"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>

        <div
          className="collapse navbar-collapse flex-grow-1 text-right"
          id="myNavbar7"
        >
          <ul className="navbar-nav ml-auto flex-nowrap">
            <li className="nav-item">
              <a className="nav-link" href="#home">
                HOME
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#aboutUs">
                ABOUT
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                SERVICES
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="#booking">
                BOOKING
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="#contactUs">
                CONTACT
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;
