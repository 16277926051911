import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { getTesimonials } from "./../services/testimonials";

class Testimonials extends Component {
  state = {
    testimonials: [],
  };

  componentDidMount() {
    this.setState({ testimonials: getTesimonials() });
  }

  render() {
    return (
      <div className="fixed3">
        <div style={{ border: "0px solid red", height: "100%", width: "100%" }}>
          <Fade duration={3000}>
            <div
              id="testimonial"
              className="carousel slide"
              data-ride="carousel"
              data-type="multi"
              data-interval="3000"
              style={{
                border: "0px solid red",
                textAlign: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <h1>TESTIMONIAL</h1>
              <ul className="carousel-indicators">
                {this.state.testimonials.map((ind, i) => (
                  <li
                    data-target="#testimonial"
                    data-slide-to={i}
                    className={i === 0 ? "active" : ""}
                    key={ind._id}
                  ></li>
                ))}
              </ul>
              <div
                className="carousel-inner"
                style={{
                  border: "0px solid blue",
                  height: "68%",
                }}
              >
                {this.state.testimonials.map((t, i) => (
                  <div
                    className={
                      i === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={t._id}
                    style={{
                      border: "0px solid blue",
                    }}
                  >
                    <div
                      style={{
                        border: "0px solid red",
                      }}
                    >
                      <h2>{t.company}</h2>
                      <p>"{t.text}"</p>
                      <hr />
                      <span>{t.name}</span>
                      <br />
                      <span>{t.title}</span>
                    </div>
                  </div>
                ))}
              </div>
              <a
                className="carousel-control-prev"
                href="#testimonial"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon"></span>
              </a>
              <a
                className="carousel-control-next"
                href="#testimonial"
                data-slide="next"
              >
                <span className="carousel-control-next-icon"></span>
              </a>
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default Testimonials;
