const services = [
  {
    _id: "0",
    img: "console.jpg",
    title: "Pre / Post Production",
    text:
      "Voluptas iste doloribus tempora reprehenderit magnam corporis non vitae nisi distinctio minus dicta ipsam, officia adipisci quas. Deleniti sequi asperiores sit adipisci!",
  },
  {
    _id: "1",
    img: "process.jpg",
    title: "Audio Production Processes",
    text:
      "Ipsum dolor sit amet consectetur adipisicing elit. Rem in quae velit ea aperiam repudiandae aspernatur ipsam delectus. Quidem, aperiam.",
  },
  {
    _id: "2",
    img: "multitrack.jpg",
    title: "Multi-Track Recording",
    text:
      "Molestiae, sed. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestiae, sed.",
  },
  {
    _id: "3",
    img: "midi.jpg",
    title: "Midi",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },
  {
    _id: "4",
    img: "foley.jpg",
    title: "Sound Effects/Foley",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },

  {
    _id: "5",
    img: "rendering.jpg",
    title: "Rendering",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },
  {
    _id: "6",
    img: "score.jpg",
    title: "Musical Score",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },
  {
    _id: "7",
    img: "adr.jpg",
    title: "Auto Dialogue Replacement",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },
  {
    _id: "8",
    img: "livesound.jpg",
    title: "Live Sound (Analog / Digital)",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },
  {
    _id: "9",
    img: "stage.jpg",
    title: "Stage Assembly (SL250)",
    text:
      "This is a wider card with supporting text below as a natural lead-in to additional",
  },
];

export function getServices() {
  return services;
}

export function getService(id) {
  return services.find((service) => service._id === id);
}
