import React, { Component } from "react";
import Fade from "react-reveal/Fade";

class Home extends Component {
  state = {};
  render() {
    return (
      <div id="home">
        <div className="tr">
          <div
            className="td"
            style={{
              border: "0px solid blue",
            }}
          >
            <Fade duration={3000}>
              <img src="/img/Logo.svg" />
            </Fade>
          </div>
        </div>
        <div className="tr">
          <div className="td">
            <Fade top duration={3000}>
              <a href="#aboutUs" className="btn btn-sm btn-outline-secondary">
                GET STARTED
              </a>{" "}
              <br />
              <a href="#aboutUs">
                <i
                  className="fa fa-angle-down"
                  aria-hidden="true"
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                    border: "0px solid red",
                    marginTop: "0",
                  }}
                ></i>
              </a>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
