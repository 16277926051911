import React, { Component } from "react";
import Fade from "react-reveal/Fade";

class Filler1 extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div
          className="fixed2 text-center"
          style={{ border: "0px solid blue", display: "grid" }}
        >
          <Fade duration={3000}>
            <div
              style={{
                border: "0px solid red",
                color: "white",
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "100",
                  color: "#fdfffc",
                }}
              >
                See how Atmos Entertainment Corp. can have an impact on your
                next project or event.
              </p>
              <i
                className="fa fa-sliders fa-rotate-270"
                aria-hidden="true"
                style={{ fontSize: "4rem" }}
              ></i>
              {/* <img src="./img/guitar.png" alt="..." style={{ width: "40vw" }} /> */}
              <p></p>
              <a href="#contactUs" className="btn btn-sm btn-primary">
                LET'S WORK TOGETHER
              </a>
            </div>
          </Fade>
        </div>
      </React.Fragment>
    );
  }
}

export default Filler1;
