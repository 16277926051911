import React, { Component } from "react";
import Fade from "react-reveal/Fade";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="container-fluid text-center" className="footer">
        <Fade duration={3000}>
          <div className="row  text-center">
            {/* <div className="col-sm-8">
            <h3>Connect</h3>
            <a href="#" className="fa-connect fa fa-facebook"></a>
            <a href="#" className="fa-connect fa fa-twitter"></a>
            <a href="#" className="fa-connect fa fa-instagram"></a>
            <a href="#" className="fa-connect fa fa-youtube"></a>
          </div> */}

            <div className="col-sm-12">
              <img src="/img/LogoWhite.svg" className="icon" />
            </div>
          </div>
          <div className="row  text-center">
            <div className="col-12">
              <span className="copyright">
                ©{new Date().getFullYear()} Atmos Entertainment Corp. All rights
                reserved.
              </span>
            </div>
          </div>
        </Fade>
      </footer>
    );
  }
}

export default Footer;
