import React, { Component } from "react";

class ContactForm extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <p style={{ fontWeight: "500", marginBottom: "10px" }}>
          YOU MAY USE THE BELOW FORM TO CONTACT US
        </p>

        <form action="#">
          <p>
            <a
              className="btn btn-sm btn-primary"
              data-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              --Select all that apply--{" "}
              <i
                className="fa fa-angle-down"
                aria-hidden="true"
                style={{
                  fontSize: "1rem",
                  color: "white",
                  border: "0px solid red",
                  marginTop: "0",
                }}
              ></i>
            </a>
          </p>
          <div
            className="collapse"
            id="collapseExample"
            style={{ marginBottom: "10px" }}
          >
            <div className="card card-body">
              <span style={{ marginBottom: "10px", fontStyle: "italic" }}>
                Select all services that apply to inquire about.
              </span>

              {this.props.data.services.map((service, i) => (
                <div className="form-check" key={service._id}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"svc_" + service._id}
                    name="services[]"
                    value={service._id}
                    onChange={(e) => this.props.onInquire(e, service)}
                    checked={this.props.data.services[i].selected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"svc_" + service._id}
                  >
                    {service.title}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control  form-control-sm"
              id="name"
              name="name"
              placeholder="Your Name"
              onChange={this.props.onChange}
              value={this.props.data.name}
            />
            {this.props.status.errors.name && (
              <div className="alert alert-danger alert-sm-danger" roll="alert">
                {this.props.status.errors.name}
              </div>
            )}
          </div>

          <div className="form-group">
            <input
              type="email"
              className="form-control  form-control-sm"
              id="email"
              name="email"
              placeholder="Your Email"
              onChange={this.props.onChange}
              value={this.props.data.email}
            />
            {this.props.status.errors.email && (
              <div className="alert alert-danger alert-sm-danger" roll="alert">
                {this.props.status.errors.email}
              </div>
            )}
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control  form-control-sm"
              id="subject"
              name="subject"
              placeholder="Your Subject"
              onChange={this.props.onChange}
              value={this.props.data.subject}
            />
            {this.props.status.errors.subject && (
              <div className="alert alert-danger" roll="alert">
                {this.props.status.errors.subject}
              </div>
            )}
          </div>

          <div className="form-group">
            <textarea
              className="form-control form-control-sm"
              id="message"
              name="message"
              placeholder="Your Message"
              onChange={this.props.onChange}
              value={this.props.data.message}
              rows="3"
            ></textarea>
            {this.props.status.errors.message && (
              <div className="alert alert-danger" roll="alert">
                {this.props.status.errors.message}
              </div>
            )}
          </div>

          <button
            className={
              this.props.onValidate || this.props.status.mailSent
                ? "btn btn-dark"
                : "btn btn-primary"
            }
            disabled={this.props.onValidate || this.props.status.mailSent}
            onClick={this.props.onHandleSubmit}
          >
            Submit
          </button>
          <div style={{ marginTop: "10px" }}>
            {this.props.status.mailSent && (
              <div className="alert alert-success" roll="alert">
                Thank you for contacting us.
              </div>
            )}
            {this.props.status.mailError && (
              <div className="alert alert-danger" roll="alert">
                {this.props.status.mailError}
              </div>
            )}
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default ContactForm;
