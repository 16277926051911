import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Headers from "./common/headers";
import { getAboutCards } from "../services/aboutCards";

class AboutUs extends Component {
  state = {
    aboutCards: [],
  };

  componentDidMount() {
    this.setState({ aboutCards: getAboutCards() });
  }

  render() {
    return (
      <React.Fragment>
        <div id="aboutUs">
          <Headers label="ABOUT US" />
          <div className="padding">
            <div className="container">
              <br />
              <Fade bottom duration={1000}>
                <div className="row">
                  <div className="col-lg-7">
                    <img src="/img/headerImage.png" alt="..." />
                  </div>
                  <div className="col-lg-5 text-center">
                    <React.Fragment>
                      <blockquote className="blockquote text-center">
                        <p
                          className="mb-0"
                          style={{
                            fontSize: "1rem",
                            fontWeight: "100",
                          }}
                        >
                          "Engineering the Soul of the Sound"
                        </p>
                        <footer
                          className="blockquote-footer"
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                          }}
                        >
                          Owner,
                          <cite title="Source Title"> Atmos Ent</cite>
                        </footer>
                      </blockquote>
                      <div
                        style={{
                          fontSize: ".75rem",
                          fontWeight: "100",
                          padding: "20px",
                          color: "#73683b",
                        }}
                        className="about-txt-border"
                      >
                        With decades of experience in the music industry, we
                        share your passion in having the highest quality sound.
                        We offer a variety of services in any genre from Hip Hop
                        to Rock to anything you do! Specializing in live sound,
                        recording real instruments and beat production. We also
                        offer stem-mixing and mastering. We can work on small to
                        large scale projects. <br />
                        <br />
                        {/* <br />
                      For the past 2 years we have been contracted with **Company
                      contracted to** travelling across Canada providing our
                      services on the Rogers Hometown Hockey Tour broadcasted on
                      SportsNet. where we are responsible for setting up all audio
                      equipment and mixing vocal and instrument sounds precisely
                      to the preferences of the performing artists and musicians.{" "}
                      <br /> */}
                        We are located in Markham, Ontario. We can also have an
                        engineer come to your studio. Book your session today.
                        <br />
                        <br />
                        See how Atmos Entertainment Corp. can have an impact on
                        your next project or event.
                      </div>
                    </React.Fragment>
                  </div>
                </div>
                <br />
              </Fade>
              <Fade>
                <div
                  className="row justify-content-center"
                  style={{ border: "0px solid red", backgroundColor: "" }}
                >
                  <div className="col-sm-8" style={{ border: "0px solid red" }}>
                    <p
                      style={{
                        fontSize: "2rem",
                        fontWeight: "100",
                        color: "#73683b",
                      }}
                      className="text-center"
                    >
                      Elevate your project to the next level.
                    </p>
                  </div>
                </div>
              </Fade>
              <div
                className="row justify-content-center"
                style={{ border: "0px solid red" }}
              >
                {this.state.aboutCards.map((ac) => (
                  <Fade left key={ac._id}>
                    <div className="col-sm-3 text-center about-fa-border">
                      <i
                        className={ac.icon + " fa-3x about-fa"}
                        aria-hidden="true"
                      ></i>
                      <br />
                      <h5 className="about-fa-txt">
                        {" "}
                        <nobr>{ac.title}</nobr>
                      </h5>
                      <p className="about-fa-txt">{ac.text} </p>
                    </div>
                  </Fade>
                ))}
              </div>
              <br /> <br />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutUs;
