import React, { Component } from "react";
import { getServices } from "./../services/services";
import NavBar from "./navbar";
import Home from "./home";
import AboutUs from "./aboutUs";
import Filler1 from "./filler1";
import Services from "./services";
import Testimonials from "./testimonials2";
import ContactUs from "./contactUs";
import Footer from "./footer";

import Joi from "joi-browser";
import axios from "axios";

//const API_PATH = "http://www.atmosentertainmentcorp.com/api/contact/index.php";
const API_PATH = "http://localhost:8080/contact";

class Main extends Component {
  state = {
    data: {
      name: "",
      email: "",
      subject: "",
      message: "",
      services: [],
    },
    status: {
      mailSent: false,
      mailError: null,
      errors: {},
    },
  };

  componentDidMount() {
    const data = { ...this.state.data };
    const services = [...getServices()];

    services.forEach((service) => {
      service.selected = false;
    });

    data.services = services;

    this.setState({ data });
  }

  handleInquire = (e, service) => {
    if (e.currentTarget.tagName === "A") e.preventDefault();

    const data = { ...this.state.data };
    const services = [...data.services];

    const index = services.indexOf(service);

    services[index] = {
      ...services[index],
      selected: !services[index].selected,
    };

    data.services = services;
    this.setState({ data });
  };

  schema = {
    name: Joi.string().min(2).max(40).required().label("Name"),
    email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email"),
    subject: Joi.string().min(3).max(40).required().label("Subject"),
    message: Joi.string().min(10).max(1000).required().label("Message"),
  };

  handleInputChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    const status = { ...this.state.status };

    data[input.name] = input.value;

    const errorMessage = this.validateProperty(input);

    if (errorMessage) status.errors[input.name] = errorMessage;
    else delete status.errors[input.name];

    this.setState({ data, status });
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false, stripUnknown: true };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    const data = { ...this.state.data };

    this.setState({ data });

    this.doSubmit();
  };

  doSubmit = (e) => {
    //Call the server
    const status = { ...this.state.status };

    axios({
      method: "post",
      url: `${API_PATH}`,
      data: this.state.data,
      headers: { "content-type": "application/json" },
    })
      .then((result) => {
        status.mailSent = result.data.sent;

        this.setState({ status });
        // console.log("then", this.state);

        console.log("result", result);
        console.log("result.data", result.data);
        console.log("result.data.sent", result.data.sent);
      })
      .catch((error) => {
        status.mailError = error.message;
        this.setState({ status });
        console.log("error.message", error.message);
      });
    console.log(this.state);

    console.log("Submitted");
  };

  render() {
    return (
      <React.Fragment>
        <div id="container">
          <NavBar />
          <Home />
          <AboutUs />
          <Filler1 />
          <Services
            services={this.state.data.services}
            onInquire={this.handleInquire}
          />
          <Testimonials />
          <ContactUs
            data={this.state.data}
            status={this.state.status}
            onValidate={this.validate()}
            onInquire={this.handleInquire}
            onChange={this.handleInputChange}
            onHandleSubmit={this.handleSubmit}
          />
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
