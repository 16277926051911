const aboutCards = [
  {
    _id: "0",
    icon: "fa fa-handshake-o",
    title: "FRIENDLY SERVICE",
    text:
      "Whether small or large scale, your project is important to us. You will be shown courtesy and respect by all staff members.",
  },
  {
    _id: "1",
    icon: "fa fa-diamond",
    title: "QUALITY WORK",
    text:
      "With extensive experience within the industry, we know the technical aspects of working with sound and follow the processes to acheive the best quality.",
  },
  {
    _id: "2",
    icon: "fa fa-line-chart",
    title: "RESULTS DRIVEN",
    text:
      "We will work with you to define goals and prioritize those goals to be done on time and on schedule.",
  },
  {
    _id: "3",
    icon: "fa fa-fire",
    title: "FIRE PRODUCT",
    text: "We strive to achieve the highest quality sound possible",
  },
];

export function getAboutCards() {
  return aboutCards;
}

export function getAboutCard(id) {
  return aboutCards.find((aboutCard) => aboutCard._id === id);
}
