import React, { Component } from "react";
import Fade from "react-reveal/Fade";

class Headers extends Component {
  state = {};
  render() {
    return (
      <Fade duration={3000}>
        <div
          className="padding"
          style={{
            // background: "linear-gradient(to right, #F8F8F8, #fdfffc)",
            paddingTop: "100px",
            boxShadow: "0px 2px 2px #DCDCDC",
            backgroundImage:
              "linear-gradient(to right, rgba(245,245,245,0.8), rgba(253,255,252,0))" /* Standard syntax (must be last) */,
          }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-12 text-center"
                style={{ border: "0px solid red" }}
              >
                <img
                  src="./img/earthTopLogo_mask.png"
                  alt=""
                  style={{ maxWidth: "600px" }}
                />
                <h2 className="section-heading">{this.props.label}</h2>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default Headers;
