const testimonials = [
  {
    _id: "0",
    company: "Quest Audio & Visual",
    text:
      "Atmos Entertainment provided us with superior service and great professionalism.  Bob is very knowledgeable and technical.",
    name: "AL MANJI",
    title: "PRESIDENT",
  },
  {
    _id: "1",
    company: "Rogers Hometown Hockey.",
    text:
      "Always a pleasure working with Bob and team every year across Canada on the Rogers Hometown Hockey tour.",
    name: "Sportsnet",
    title: "",
  },
  {
    _id: "2",
    company: "456 Kids Entertainment",
    text:
      "Our project turned out with excellent quality.  Thank you Amos Entertainment!",
    name: "ADAM GRANDVILLE",
    title: "ART DIRECTOR",
  },
];

export function getTesimonials() {
  return testimonials;
}

export function getTestimonial(id) {
  return testimonials.find((testimonial) => testimonial._id === id);
}
