import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import ContactForm from "./contact";
import Headers from "./common/headers";

class ContactUs extends Component {
  state = {};
  render() {
    const selectedArr = this.props.data.services.filter(
      (item) => item.selected === true
    );
    console.log("contact us this.props:", this.props);

    return (
      <React.Fragment>
        <div
          id="contactUs"
          style={{
            border: "0px solid red",
          }}
        >
          <Headers label="CONTACT US" />
          <div style={{ display: "grid", border: "0px solid red" }}>
            <div
              style={{
                border: "0px solid red",
                alignSelf: "center",
                justifySelf: "center",
                display: selectedArr.length ? "inline" : "none",
              }}
            >
              <div
                className="alert alert-info"
                role="alert"
                style={{ marginTop: "25px" }}
              >
                YOU HAVE SELECTED SERVICES TO INQUIRE ABOUT. PLEASE COMPLETE THE
                FORM BELOW AND CLICK SUBMIT.
              </div>
            </div>
          </div>
          <div className="padding" style={{ border: "0px solid red" }}>
            <div className="container" style={{ border: "0px solid red" }}>
              <div
                className="row justify-content-center"
                style={{ border: "0px solid blue" }}
              >
                <Fade left>
                  <div
                    className="col-lg-6 col-med-6 col-sm-6 col-xs-12"
                    style={{
                      border: "0px solid red",
                      fontWeight: "100",
                      color: "#73683b",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>HOW TO REACH US</span>
                    <br />
                    You may contact us in a variety of ways. You can fill out
                    our contact form and optionally select any of the services
                    listed. You may manually send us an email or give us a call
                    with the contact info below.
                    <br />
                    <br />
                    <span style={{ fontWeight: "500" }}>OUR ADDRESS</span>
                    <br />
                    We are located in Markham, Ontario. <br />
                    Please visit by appointment only.
                    <br />
                    <br />
                    <div style={{ padding: "5px" }}>
                      <i
                        className="fa fa-phone fa-fw fa-contact"
                        aria-hidden="true"
                      ></i>
                      &nbsp;416.820.3030
                    </div>
                    <div
                      style={{ borderTop: "1px solid #EEE", padding: "5px" }}
                    >
                      <i
                        className="fa fa-envelope-o fa-fw fa-contact"
                        aria-hidden="true"
                      ></i>
                      &nbsp;info@AtmosEntertainmentCorp.com
                    </div>
                    <div
                      style={{
                        borderTop: "1px solid #EEE",

                        padding: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <i
                        className="fa fa-globe fa-fw fa-contact"
                        aria-hidden="true"
                      ></i>
                      &nbsp;wwww.AtmosEntertainmentCorp.com
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div
                    className="col-lg-6 col-med-6 col-sm-6 col-xs-12"
                    style={{ border: "0px solid red" }}
                  >
                    <ContactForm
                      data={this.props.data}
                      status={this.props.status}
                      onInquire={this.props.onInquire}
                      onChange={this.props.onChange}
                      onValidate={this.props.onValidate}
                      onHandleSubmit={this.props.onHandleSubmit}
                    />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUs;
