import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Headers from "./common/headers";

class Services extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <div id="services">
          <Headers label="SERVICES AND EXPERTISE" />
          <p></p>

          <div className="padding">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-3">
                {this.props.services.map((service, i) => (
                  <Fade left key={service._id}>
                    <div className="col mb-4">
                      <div
                        className="card"
                        style={{
                          background: this.props.services[i].selected
                            ? "#F0F0F0"
                            : "#fdfffc",
                        }}
                      >
                        <img
                          src={"/img/" + service.img}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body">
                          <h5 className="card-title">
                            {service.title.toUpperCase()}
                          </h5>
                          {/* <p className="card-text">{service.text}</p> */}
                          {/* <a
                          href=""
                          className="badge badge-pill badge-primary"
                          onClick={(e) => this.props.onInquire(e, service)}
                        >
                          BOOK
                        </a>{" "} */}
                          &nbsp;&nbsp;
                          <a
                            href="#"
                            className={
                              this.props.services[i].selected
                                ? "badge badge-pill badge-success"
                                : "badge badge-pill badge-info"
                            }
                            onClick={(e) => this.props.onInquire(e, service)}
                          >
                            INQUIRE
                          </a>
                          <div
                            style={{
                              display: !this.props.services[i].selected
                                ? "none"
                                : "inline",
                              fontSize: ".75rem",
                              color: "#364156",
                            }}
                          >
                            {" "}
                            &nbsp;&nbsp;
                            <br />
                            <br />
                            <div className="alert alert-info" role="alert">
                              YOU MAY SELECT FURTHER SERVICES TO INQUIRE ABOUT
                              OR PROCEED WITH YOUR SERVICE INQUIRY.
                            </div>
                            <a
                              href="#contactUs"
                              className="btn btn-sm btn-success"
                            >
                              PROCEED WITH INQUIRY
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                ))}
              </div>

              <br />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Services;
